/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Plugin: https://developer.zendesk.com/embeddables/docs/widget/introduction
 * options: https://developer.zendesk.com/embeddables/docs/widget/settings
 * commands: https://developer.zendesk.com/api-reference/widget/core/#commands
 */
import { themingInstance } from '@exchange/libs/utils/theming/src';

export interface ZendeskUpdatePath {
  title?: string;
  url: string;
}

export interface ZendeskWidget {
  close: () => void;
  helpCenterSetSuggestions: (value: Record<string, unknown>) => void;
  hide: () => void;
  open: () => void;
  setLocale: (lang: string) => void;
  show: () => void;
  updatePath: (path: ZendeskUpdatePath) => void;
  updateSettings: (value: Record<string, unknown>) => void;
}

export type UpdateZendeskPath = (updatePathData: ZendeskUpdatePath) => void;
export type UpdateZendeskInfo = (value: { loaded: boolean; obj: ZendeskWidget }) => void;
export type SnippetData = {
  url: string;
  id: string;
};

const getZESettings = () => {
  const { getColor } = themingInstance;

  return {
    cookies: false,
    webWidget: {
      /**
       * for some reason resulting z-index is always less by one than specified within zIndex
       * Widget should not be placed under x-flex-table-header that uses `--table-sticky-header-z-index`
       */
      zIndex: 81,
      offset: {
        vertical: '6px', // to align it with first toast
      },
      color: {
        theme: getColor('--v-theme-primary'),
        launcher: getColor('--v-theme-primary'),
        launcherText: getColor('--text-0'),
        button: getColor('--v-theme-primary'),
        resultLists: getColor('--text-0'),
        header: getColor('--v-theme-primary'),
        articleLinks: getColor('--v-theme-primary'),
      },
      launcher: {
        mobile: {
          labelVisible: false,
        },
      },
    },
  };
};

const install = (options: { loadAndShow: boolean; language: string; snippetData: SnippetData; updateZendeskInfo: UpdateZendeskInfo }): ZendeskWidget => {
  if (!options.snippetData.url || options.snippetData.url.length === 0) {
    throw new Error('Zendesk Web Widget Key is missing');
  }

  const zELang = options.language;
  const otWindow = window as any;

  otWindow.zE = otWindow.zE || [];
  const { zE } = otWindow;

  otWindow.zESettings = getZESettings();

  zE.load = (lang: string = zELang, action: 'show' | 'hide' = 'hide') => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = false;
    script.id = options.snippetData.id;
    script.src = options.snippetData.url;

    const [firstScript] = document.getElementsByTagName('script');

    firstScript?.parentNode?.insertBefore(script, firstScript || null);

    const zEScript = document.querySelector(`#${options.snippetData.id}`);

    if (!zEScript) {
      return;
    }

    zEScript.addEventListener('load', () => {
      otWindow.zE('webWidget', 'setLocale', lang);

      otWindow.zE('webWidget', action);
      options.updateZendeskInfo({
        loaded: true,
        obj: zE,
      });
    });
  };

  zE.setLocale = (lang: string) => {
    otWindow.zE('webWidget', 'setLocale', lang);
  };

  zE.show = () => {
    otWindow.zE('webWidget', 'show');
  };

  zE.hide = () => {
    otWindow.zE('webWidget', 'hide');
  };

  zE.open = () => {
    otWindow.zE('webWidget', 'open');
  };

  zE.close = () => {
    otWindow.zE('webWidget', 'close');
  };

  zE.updateSettings = (value: Record<string, unknown>) => {
    otWindow.zE('webWidget', 'updateSettings', value);
  };

  zE.updatePath = (path: ZendeskUpdatePath) => {
    otWindow.zE('webWidget', 'updatePath', path);
  };

  zE.helpCenterSetSuggestions = (value: Record<string, unknown>) => {
    otWindow.zE('webWidget', 'helpCenter:setSuggestions', value);
  };

  zE.load(zELang, options.loadAndShow ? 'show' : 'hide');

  return zE;
};

export default install;
