/* eslint-disable camelcase */
import { type WSFrontendErrors, WSClosedErrorMessage } from './websocket-model';

export const wsSportChannelNames = {
  ACCOUNT_HISTORY: 'ACCOUNT_HISTORY',
  MARKET_TICKER: 'MARKET_TICKER',
  ORDER_BOOK: 'ORDER_BOOK',
  PRICE_TICKS: 'PRICE_TICKS',
  SYSTEM: 'SYSTEM',
  TRADING: 'TRADING',
} as const;
export type WSChannelNames = (typeof wsSportChannelNames)[keyof typeof wsSportChannelNames];

export enum WSIncomingEventTypesSpecial {
  AUTHENTICATED = 'AUTHENTICATED',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum WSIncomingEventTypes {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ACTIVE_ORDERS_SNAPSHOT = 'ACTIVE_ORDERS_SNAPSHOT',
  BALANCES_SNAPSHOT = 'BALANCES_SNAPSHOT',
  CANDLESTICK = 'CANDLESTICK',
  CANDLESTICK_SNAPSHOT = 'CANDLESTICK_SNAPSHOT',
  CONNECTION_CLOSING = 'CONNECTION_CLOSING',
  ERROR = 'ERROR',
  AUTH_ERROR = 'AUTH_ERROR',
  HEARTBEAT = 'HEARTBEAT',
  INACTIVE_ORDERS_SNAPSHOT = 'INACTIVE_ORDERS_SNAPSHOT',
  MARKET_TICKER_UPDATES = 'MARKET_TICKER_UPDATES',
  MARKET_UPDATES = 'MARKET_UPDATES',
  ORDER_BOOK_SNAPSHOT = 'ORDER_BOOK_SNAPSHOT',
  ORDER_BOOK_UPDATE = 'ORDER_BOOK_UPDATE',
  BOOKED = 'BOOKED', // TRADING => ORDER_CREATED
  DONE = 'DONE', // TRADING => ORDER_CREATION_FAILED | TRADING => ORDER_CANCELLED
  PRICE_POINT_UPDATES = 'PRICE_POINT_UPDATES',
  PRICE_TICK = 'PRICE_TICK',
  PRICE_TICK_HISTORY = 'PRICE_TICK_HISTORY',
  SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED',
}

export enum WSOutgoingEventTypesSpecial {
  AUTHENTICATE = 'AUTHENTICATE',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum WSOutgoingEventTypes {
  UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
  CREATE_ORDER = 'CREATE_ORDER',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CANCEL_ALL_ORDERS = 'CANCEL_ALL_ORDERS',
}

export interface WSChannelBase {
  name: WSChannelNames;
  [key: string]: unknown;
}

export interface WSOutgoingSubscribeMessagePayload {
  type: WSOutgoingEventTypesSpecial.SUBSCRIBE;
  channels: Array<WSChannelBase>;
}
export interface WSIncomingSubscribeMessagePayload {
  type: WSIncomingEventTypesSpecial.SUBSCRIPTIONS;
  channels: Array<WSChannelBase>;
}

export interface WSOutgoingUnsubscribeMessagePayload {
  type: WSOutgoingEventTypesSpecial.UNSUBSCRIBE;
  channels: Array<string>;
}
export interface WSIncomingUnsubscribeMessagePayload {
  type: WSIncomingEventTypesSpecial.UNSUBSCRIBED;
  channel_name: WSChannelNames;
}

export interface WSOutgoingAuthenticateMessagePayload {
  type: WSOutgoingEventTypesSpecial.AUTHENTICATE;
  api_token: string;
  request_id?: string;
}
export interface WSIncomingAuthenticateMessagePayload {
  type: WSIncomingEventTypesSpecial.AUTHENTICATED;
}

export interface WSIncomingBasicMessagePayload {
  type: WSIncomingEventTypes | WSErrors | WSFrontendErrors;
  channel_name?: WSChannelNames;
  status?: string;
}

export interface WSOutgoingBasicMessagePayload {
  type: WSOutgoingEventTypes;
  channels?: Array<WSChannelBase>;
}

export type WSOutgoingCreateOrderPayload = Record<string, string | boolean> & {
  instrument_code: string;
  amount: string;
  side: 'BUY' | 'SELL';
  type: 'MARKET' | 'LIMIT' | 'STOP';
};
export interface WSOutgoingCreateOrderMessagePayload {
  type: WSOutgoingEventTypes.CREATE_ORDER;
  order: WSOutgoingCreateOrderPayload;
}

export interface WSErrorMessage {
  type: WSIncomingEventTypes.ERROR;
  channel_name: WSChannelNames;
  error: WSErrors | string;
  time: string;
}

export interface WSSpotCloseConnectionMessage {
  type: WSIncomingEventTypes.CONNECTION_CLOSING;
}

export type WSErrors = WSGeneralErrors | WSAuthorizationErrors;

export type WSIncomingMessagePayload =
  | WSIncomingAuthenticateMessagePayload
  | WSIncomingSubscribeMessagePayload
  | WSIncomingUnsubscribeMessagePayload
  | WSIncomingBasicMessagePayload
  | WSErrorMessage
  | WSSpotCloseConnectionMessage
  | WSClosedErrorMessage;

export type WSOutgoingMessagePayload =
  | WSOutgoingAuthenticateMessagePayload
  | WSOutgoingSubscribeMessagePayload
  | WSOutgoingUnsubscribeMessagePayload
  | WSOutgoingCreateOrderMessagePayload
  | WSOutgoingBasicMessagePayload;

// https://docs.onetrading.com#general-errors
export enum WSGeneralErrors {
  AMOUNT_NOT_NUMERIC = 'AMOUNT_NOT_NUMERIC', // Amount should be numeric, in form of string e.g. "1234.456"
  AMOUNT_REQUIRED = 'AMOUNT_REQUIRED', // Amount field is required.
  CANDLESTICKS_TIME_RANGE_TOO_BIG = 'CANDLESTICKS_TIME_RANGE_TOO_BIG', // Would return more candlesticks than a maximum limit of 1500, please choose smaller time frame.
  CANNOT_SEND_TO_OWN_ADDRESS = 'CANNOT_SEND_TO_OWN_ADDRESS', // Sending to own address is prohibited.
  CSV_EXPORT_EXPIRED = 'CSV_EXPORT_EXPIRED', // Export link already expired, please generate new one again.
  CURRENCY_REQUIRED = 'CURRENCY_REQUIRED', // Currency parameter is required.
  CURRENCY_NOT_FOUND = 'CURRENCY_NOT_FOUND', // Currency could not be found.
  CURRENCY_NOT_SUPPORTED = 'CURRENCY_NOT_SUPPORTED', // Currency is not supported at the moment.
  DEPOSIT_ADDRESS_NOT_USED = 'DEPOSIT_ADDRESS_NOT_USED', // Deposit address is not used.
  EMPTY_JSON = 'EMPTY_JSON', // JSON payload should not be empty.
  EMPTY_PAYLOAD = 'EMPTY_PAYLOAD', // Payload of WSS message should not be empty.
  FAILED_TO_SERVICE_QUERY = 'FAILED_TO_SERVICE_QUERY', // Candlestick channel subscription has failed.
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS', // Your funds are insufficient to execute the action.
  INVALID_ACCOUNT_HISTORY_FROM_TIME = 'INVALID_ACCOUNT_HISTORY_FROM_TIME', // Invalid format of from parameter, it should be ISO8601 e.g. 2019-07-01T12:30:55.731Z.
  INVALID_ACCOUNT_HISTORY_MAX_PAGE_SIZE = 'INVALID_ACCOUNT_HISTORY_MAX_PAGE_SIZE', // Invalid max page size for paginated responses.
  INVALID_ACCOUNT_HISTORY_TIME_PERIOD = 'INVALID_ACCOUNT_HISTORY_TIME_PERIOD', // Time period of a query invalid.
  INVALID_ACCOUNT_HISTORY_TO_TIME = 'INVALID_ACCOUNT_HISTORY_TO_TIME', // Invalid format of to parameter, it should be ISO8601 e.g. 2019-07-01T12:30:55.731Z.
  INVALID_ADDRESS = 'INVALID_ADDRESS', // Address is invalid, please re-check it.
  INVALID_AMOUNT = 'INVALID_AMOUNT', // Amount is invalid or malformed, it has to be decimal in string format e.g. "1234.456"
  INVALID_CANDLESTICKS_GRANULARITY = 'INVALID_CANDLESTICKS_GRANULARITY', // Candlestick granularity is not valid, is has to be one of MINUTES, HOURS, DAYS, WEEKS and MONTHS.
  INVALID_CANDLESTICKS_UNIT = 'INVALID_CANDLESTICKS_UNIT', // Candlestick unit is not valid, supported combinations explained in candlesticks description.
  INVALID_DESTINATION_TAG = 'INVALID_DESTINATION_TAG', // Destination tag for withdrawal is not valid.
  INVALID_DESTINATION_TAG_TYPE = 'INVALID_DESTINATION_TAG_TYPE', // Destination tag type for withdrawal is not valid, or it is not required to be specified for given currency.
  INVALID_FEE = 'INVALID_FEE', // Fee is invalid
  INVALID_ORDER_BOOK_DEPTH = 'INVALID_ORDER_BOOK_DEPTH', // Orderbook depth is not valid number.
  INVALID_ORDER_BOOK_LEVEL = 'INVALID_ORDER_BOOK_LEVEL', // Invalid orderbook level, supported levels are 1,2 and 3. You can find out more in order-book description.
  INVALID_PAGE_CURSOR = 'INVALID_PAGE_CURSOR', // Page cursor is not correct, please make sure you copied it completely.
  INVALID_PAYLOAD = 'INVALID_PAYLOAD', // Payload had not correct format.
  INVALID_PRECISION = 'INVALID_PRECISION', // Precision is not valid.
  INVALID_TIME_RANGE = 'INVALID_TIME_RANGE', // Time range is too big, please check corresponding limitations of endpoint you called.
  INVALID_TRADE_ID = 'INVALID_TRADE_ID', // trade_id provided is not valid uuid.
  INVALID_UI_ACCOUNT_SETTINGS = 'INVALID_UI_ACCOUNT_SETTINGS', // UI settings are not in correct JSON format.
  // eslint-disable-next-line max-len
  INVALID_AUTO_CLOSE_TIMER = 'INVALID_AUTO_CLOSE_TIMER', // Client specified an order auto-close timer which is not invalid. A timeout in a valid range specified in milliseconds is expected.
  MALFORMED_JSON = 'MALFORMED_JSON', // JSON payload could not be parsed, please check if it has a valid format.
  MIN_AMOUNT_SIZE_NOT_SATISFIED = 'MIN_AMOUNT_SIZE_NOT_SATISFIED', // Minimal amount for transfer is not met.
  MINIMUM_TRANSACTION_AMOUNT_NOT_SATISFIED = 'MINIMUM_TRANSACTION_AMOUNT_NOT_SATISFIED', // Minimal amount for transaction is not met.
  MISSING_ACCOUNT_ID = 'MISSING_ACCOUNT_ID', // account_id is missing in request.
  MISSING_ACCOUNT_VERIFICATION = 'MISSING_ACCOUNT_VERIFICATION', // Account is not verified, please verify first.
  MISSING_CANDLESTICK_GRANULARITY = 'MISSING_CANDLESTICK_GRANULARITY', // Missing candlestick granularity.
  MISSING_CANDLESTICKS_PERIOD_PARAM = 'MISSING_CANDLESTICKS_PERIOD_PARAM', // Required period parameter is missing.
  MISSING_CANDLESTICKS_UNIT_PARAM = 'MISSING_CANDLESTICKS_UNIT_PARAM', // Required unit parameter is missing.
  MISSING_FROM_PARAM = 'MISSING_FROM_PARAM', // Requited or mutually inclusive parameter from is missing.
  MISSING_INSTRUMENTS = 'MISSING_INSTRUMENTS', // Instruments field in a payload is missing.
  MISSING_ORDER_ID = 'MISSING_ORDER_ID', // order_id is missing.
  MISSING_PROPERTIES = 'MISSING_PROPERTIES', // Missing instrument code filter for candlestick channel.
  MISSING_TO_PARAM = 'MISSING_TO_PARAM', // Required or mutually inclusive to parameter is missing.
  MISSING_TRADE_ID = 'MISSING_TRADE_ID', // trade_id is missing in the request.
  ONLY_ONE_ERC20_ADDRESS_ALLOWED = 'ONLY_ONE_ERC20_ADDRESS_ALLOWED', // Only one ERC_20 address is allowed.
  OPERATION_DISABLED = 'OPERATION_DISABLED', // Operation is disabled. Please contact customer support.
  PAYOUT_ACCOUNT_MISMATCH = 'PAYOUT_ACCOUNT_MISMATCH', // Payout account does not match the currency assigned to it.
  PAYOUT_ACCOUNT_NOT_FOUND = 'PAYOUT_ACCOUNT_NOT_FOUND', // Payout account has not been found.
  RECIPIENT_REQUIRED = 'RECIPIENT_REQUIRED', // Recipient was not specified in request.
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE', // Underlying service was not able to answer in time, please try again.
  SUBSCRIPTION_ALREADY_EXISTS = 'SUBSCRIPTION_ALREADY_EXISTS', // Subscription already exists, please unsubscribe first.
  SUSPENDED_TRADING_FOR_ACCOUNT = 'SUSPENDED_TRADING_FOR_ACCOUNT', // Trading has been suspended for this account. Please contact support.
  SUSPENDED_WITHDRAW_FOR_ACCOUNT = 'SUSPENDED_WITHDRAW_FOR_ACCOUNT', // Withdrawals have been suspended for this account. Please contact support.
  TRANSACTION_RATE_LIMIT_REACHED = 'TRANSACTION_RATE_LIMIT_REACHED', // Too many transactions attempts.
  UNABLE_TO_CREATE_DEPOSIT_ADDRESS = 'UNABLE_TO_CREATE_DEPOSIT_ADDRESS', // Could not create a deposit address.
  UNDEFINED_ERROR = 'UNDEFINED_ERROR', // Undefined error occurred.
  WALLET_MAINTENANCE = 'WALLET_MAINTENANCE', // Wallets are in maintenance, please try again later or visit our status page.
  WITHDRAWAL_AMOUNT_TOO_LOW = 'WITHDRAWAL_AMOUNT_TOO_LOW', // Withdrawal amount is too low for withdrawal to be executed.
  WITHDRAWAL_LIMIT_EXCEEDED = 'WITHDRAWAL_LIMIT_EXCEEDED', // Withdrawal size is too large.
  WITHDRAWAL_GLOBAL_LIMIT_EXCEEDED = 'WITHDRAWAL_GLOBAL_LIMIT_EXCEEDED', // Global limit for withdrawal has been exceeded. Please contact support.
}

// https://docs.onetrading.com#authorization-errors
export const wsSportSAuthorizationErrors = {
  AUTH_ERROR: 'AUTH_ERROR',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  APIKEY_EXPIRED: 'APIKEY_EXPIRED', // The apikey is not valid anymore and has to be replaced.
  APIKEY_REVOKED: 'APIKEY_REVOKED', // The apikey has been revoked. It cannot be activated again, but has to be replaced.
  CLIENT_IP_BLOCKED: 'CLIENT_IP_BLOCKED', // The client ip of the request does not match any of the allowed IPs associated with this apikey.
  MISSING_CREDENTIALS: 'MISSING_CREDENTIALS', // No apikey was included in the request. Send the apikey via the Authorization header.
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS', // The provided apikey was malformed. Verify that the Authorization header contains a complete apikey and the Bearer prefix.
  INTERNAL_ERROR: 'INTERNAL_ERROR', // Authenticating the request failed, it can be retried.
  INVALID_APIKEY: 'INVALID_APIKEY', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  // eslint-disable-next-line max-len
  INVALID_AUDIENCE: 'INVALID_AUDIENCE', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  // eslint-disable-next-line max-len
  INVALID_AUTHORIZED_PARTY: 'INVALID_AUTHORIZED_PARTY', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  // eslint-disable-next-line max-len
  INVALID_DEVICE_ID: 'INVALID_DEVICE_ID', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  // eslint-disable-next-line max-len
  INVALID_IP_RESTRICTION: 'INVALID_IP_RESTRICTION', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  INVALID_ISSUER: 'INVALID_ISSUER', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  INVALID_ORIGIN: 'INVALID_ORIGIN', // The request included an Origin header that was not accepted. Do not send the Origin header.
  INVALID_SCOPES: 'INVALID_SCOPES', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  // eslint-disable-next-line max-len
  INVALID_SUBJECT: 'INVALID_SUBJECT', // An apikey was found but is invalid. Ensure that the full apikey is included in the request. If the problem persists, please contact support.
  MISSING_PERMISSION: 'MISSING_PERMISSION', // The apikey does not grant the required permissions for this endpoint. Create a new apikey and ensure to select an appropriate scope.
} as const;
export type WSAuthorizationErrors = (typeof wsSportSAuthorizationErrors)[keyof typeof wsSportSAuthorizationErrors];
