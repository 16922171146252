/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Plugin: https://developer.zendesk.com/embeddables/docs/widget/introduction
 * options: https://developer.zendesk.com/embeddables/docs/widget/settings
 * https://developer.zendesk.com/documentation/classic-web-widget-sdks/chat-widget/customising-the-widget/changing-the-widget-language/
 */
import { type RouteLocationNormalized } from 'vue-router';

import { checkIsCurrentAccountRouteOT } from '@exchange/routing';

import installZendesk, { type ZendeskUpdatePath, type ZendeskWidget } from './widget-settings';

export interface ZendeskInfo {
  disabled: boolean;
  loaded: boolean;
  zE?: ZendeskWidget;
}

type GetLocale = () => string;

class ZendeskService {
  private readonly ZENDESK_KEY = process.env.VUE_APP_ZENDESK_KEY || '';

  private readonly ZENDESK_ID = 'ze-snippet';

  private readonly snippetUrl = (key: string = this.ZENDESK_KEY): string => `https://static.zdassets.com/ekr/snippet.js?key=${key}`;

  private zendeskInfo: ZendeskInfo = {
    disabled: true,
    loaded: false,
    zE: undefined,
  };

  private getLocale: undefined | GetLocale;

  private updateZendeskInfo = (value: { disabled?: boolean; loaded?: boolean; obj?: ZendeskWidget }) => {
    if (value.disabled !== undefined) {
      this.zendeskInfo.disabled = value.disabled;
    }

    if (value.loaded !== undefined) {
      this.zendeskInfo.loaded = value.loaded;
    }

    if (value.obj !== undefined) {
      this.zendeskInfo.zE = value.obj;
    }
  };

  public init = async (getLocale: GetLocale) => {
    this.getLocale = getLocale;
    this.loadZendesk({ show: await this.shouldShowWidget() });
  };

  public loadZendesk = ({ show } = { show: false }) => {
    if (this.zendeskInfo.zE || !this.getLocale) {
      return;
    }

    installZendesk({
      loadAndShow: show,
      language: this.getLocale(),
      snippetData: {
        url: this.snippetUrl(),
        id: this.ZENDESK_ID,
      },
      updateZendeskInfo: this.updateZendeskInfo.bind(this),
    });

    this.updateZendeskInfo({ disabled: false });
  };

  public show = () => {
    if (!this.zendeskInfo.zE) {
      this.loadZendesk({ show: true });
    } else {
      this.zendeskInfo.zE?.show();
    }
  };

  public hide = () => {
    this.zendeskInfo.zE?.hide();
  };

  public close = () => {
    this.zendeskInfo.zE?.close();
  };

  public setLocale = (lang: string) => {
    this.zendeskInfo.zE?.setLocale(lang);
  };

  public updateCookies = (value: boolean) => {
    this.zendeskInfo.zE?.updateSettings({
      cookies: value,
    });
  };

  private shouldShowWidget = async () =>
    new Promise<boolean>((resolve) => {
      setTimeout(() => {
        const isAccount = checkIsCurrentAccountRouteOT();

        /* POOR DESIGN
      const isXS = () => document.querySelector('#app')?.getAttribute('class')?.includes('xs');
      const isInstantTrade = !checkIsCurrentEOTCRoute();
      return resolve(checkIsCurrentAccountRouteOT() || (checkIsCurrentEOTCRoute() && !isXS()));
      */

        return resolve(isAccount);
      }, 1_000);
    });

  private updateZendeskPath = (updatePathData: ZendeskUpdatePath) => {
    this.zendeskInfo.zE?.updatePath(updatePathData);
    this.zendeskInfo.zE?.helpCenterSetSuggestions({ url: true });
    this.zendeskInfo.zE?.helpCenterSetSuggestions({ labels: [updatePathData.title] });
  };

  private generatePathTitle = (routeName: string | undefined | null) => {
    if (routeName) {
      return routeName.split('.').join('');
    }

    return undefined;
  };

  public routeGuard = async (to: RouteLocationNormalized) => {
    if (this.zendeskInfo.disabled) {
      return;
    }

    if (await this.shouldShowWidget()) {
      const updatePathData: ZendeskUpdatePath = {
        title: this.generatePathTitle(to.name?.toString()),
        url: to.fullPath,
      };

      this.updateZendeskPath(updatePathData);
      this.show();
    } else if (this.zendeskInfo.loaded) {
      this.close();
      this.hide();
    }
  };
}

const zs = new ZendeskService();

export default zs;
