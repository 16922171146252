import { isEmpty } from 'lodash/fp';
import type { RouteLocationRaw } from 'vue-router';

export const getFullPath = (url?: string) => {
  const ulrToUse = url || window.location.toString();
  const u = new URL(ulrToUse);
  let discardedPart = u.origin; /** web + android */

  if (u.hostname === '') {
    /** ios */
    discardedPart = u.protocol;
  }

  const slug = u.href.split(discardedPart).pop();

  return slug || '/';
};

export const getSearchParam = (paramName: string, search?: string) => {
  const searchToUse = search || window.location.search;

  const urlSearchParams = new URLSearchParams(searchToUse);

  return urlSearchParams.get(paramName);
};

const formLocationQuery = (search: string) =>
  search
    .substring(1)
    .split(/&/g)
    .map((q) => q.split('=').map((e) => e.trim()))
    .reduce((acc, cur) => {
      const [k, v] = cur;

      if (v !== undefined && k !== undefined) {
        acc[k] = v;
      }

      return acc;
    }, {});

export const createRouteLocationRawFromUrl = (url?: string) => {
  const ulrToUse = url || window.location.toString();

  try {
    const u = new URL(ulrToUse);

    const { hash, search } = u;
    let query: Dictionary<unknown> | undefined;

    if (search) {
      query = formLocationQuery(search);
    }

    return {
      path: u.pathname || '/',
      ...(!isEmpty(query) && { query }),
      ...(hash && { hash }),
    } as RouteLocationRaw;
  } catch (e) {
    return {
      path: '/',
    };
  }
};

export const createRouteFullPathFromUrl = (url?: string) => {
  const ulrToUse = url || window.location.toString();

  try {
    const u = new URL(ulrToUse);

    return `${u.pathname}${u.search}${u.hash}`;
  } catch (e) {
    return '/';
  }
};

export const getDecodedUri = (s?: string) => {
  if (!s) {
    return undefined;
  }

  try {
    return decodeURIComponent(s);
  } catch (e) {
    return '/';
  }
};
