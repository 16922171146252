import { nanoid } from 'nanoid';
import { RouteLocation, RouteLocationNormalized } from 'vue-router';

import { authService, appPlatformAuthenticator } from '@exchange/libs/auth/service/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { getDecodedUri, createRouteLocationRawFromUrl } from './helpers';
import { checkIsBiometricRoute, checkIsNativeAuthCallbackRoute, checkIsWebAuthCallbackRoute, getNoAuthRequiredForRoute } from './routes';

export const getNextPathUponLogin = async () => {
  const returnToUrl = await authService.signInCallback();
  const cSplit = (s: string) => s.split(/\?redirect=(.*)/s);

  const [, value] = cSplit(returnToUrl).map((e) => e.trim());
  const nextPath = getDecodedUri(value) || returnToUrl;
  const nextRoute = createRouteLocationRawFromUrl(`${process.env.VUE_APP_URL}${nextPath}`);

  nonProdConsoleInfo('Upon login going to: ', nextPath, 'route', nextRoute);

  return nextRoute;
};

const getRouteWithRedirectQuery = (name: string, to: RouteLocationNormalized) =>
  ({
    name,
    query: { redirect: encodeURI(to.fullPath) },
  }) as unknown as RouteLocationNormalized;

const getNextPageForNative = async (to: RouteLocationNormalized, trackingId: string) => {
  const [shouldAppBeLocked, isRefreshTokeExpired] = await Promise.all([appPlatformAuthenticator.shouldAppBeLocked(trackingId), authService.isRefreshTokeExpired()]);

  if (shouldAppBeLocked) {
    if (!isRefreshTokeExpired) {
      return {
        shouldChangeRoute: true,
        to: getRouteWithRedirectQuery('biometric.unlock', to),
      };
    }
  }

  await appPlatformAuthenticator.wentToInactiveAt.set(0);

  return {
    shouldChangeRoute: false,
    to,
  };
};

const nativeGetNextPageNonProdConsoleInfo = (shouldBeNext: { to: RouteLocationNormalized; shouldChangeRoute: boolean }, trackingId: string) => {
  nonProdConsoleInfo(
    `RM::${trackingId}::next page for native: should change route`,
    shouldBeNext.shouldChangeRoute,
    ' going to: ',
    'fullPath',
    shouldBeNext.to.fullPath,
    ' name: ',
    shouldBeNext.to.name,
    ' query: ',
    shouldBeNext.to.query,
  );

  return shouldBeNext as { shouldChangeRoute: boolean; to: RouteLocation };
};

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const trackingId = nanoid();

  nonProdConsoleInfo(`RM::${trackingId}::`, 'to', to.fullPath, 'from', from.fullPath);

  if (checkIsNativeAuthCallbackRoute(to)) {
    const { biometricEnrolled, deviceCredEnrolled } = appPlatformAuthenticator;

    if (!biometricEnrolled.value && !deviceCredEnrolled.value) {
      return {
        ...getRouteWithRedirectQuery('biometric.unlock', to),
        replace: true,
      };
    }

    return {
      path: from.fullPath,
      replace: true,
    };
  }

  if (checkIsBiometricRoute(to) || checkIsBiometricRoute(from) || checkIsWebAuthCallbackRoute(to)) {
    nonProdConsoleInfo(`RM::${trackingId}::`, 'no route check access required');

    return undefined;
  }

  const defaultPublicRoute = getRouteWithRedirectQuery('exchange', to);
  const noAuthRequired = getNoAuthRequiredForRoute(to);
  const isNativePlatform = capIsNativePlatform();

  const proceedBasedOnAccess = async (hasAccess: boolean) => {
    if (!isNativePlatform) {
      /** WEB */
      if (hasAccess) {
        return undefined;
      }

      return defaultPublicRoute;
    }

    if (hasAccess) {
      const shouldBeNext = await getNextPageForNative(to, trackingId);

      nativeGetNextPageNonProdConsoleInfo(shouldBeNext, trackingId);

      if (shouldBeNext.shouldChangeRoute) {
        return shouldBeNext.to;
      }

      return undefined;
    }

    const shouldBeNext = await getNextPageForNative(defaultPublicRoute, trackingId);

    nativeGetNextPageNonProdConsoleInfo(shouldBeNext, trackingId);

    return shouldBeNext.to;
  };

  const hasAccess = await authService.checkRouteAccess({ noAuthRequired, isNativePlatform, trackingId });

  return proceedBasedOnAccess(hasAccess);
};
