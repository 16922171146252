import persistanceHelper from '@exchange/helpers/persistance-helper';
import { LayoutSettings } from '@exchange/layouts/layout-model';
import { chartIntervals } from '@exchange/libs/charting/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

/**
 * Width of the market column which cannot be changed by the user was changed in code.
 * If existing layout is stored in localStorage this change would never take effect.
 */
const layoutUpdate = () => {
  const storedLayout = persistanceHelper.getObjFromJSON<LayoutSettings | undefined>(CONSTANTS.TRADING_LAYOUT_SETTINGS_KEY, undefined);

  if (storedLayout === undefined) {
    return;
  }

  const defaultBrowserFontSize = Number(process.env.VUE_APP_BASE_FONT_SIZE ?? 16);

  const with300 = 300 / defaultBrowserFontSize;

  const updatedLayout = { ...storedLayout, columns: { ...storedLayout.columns, market: { ...storedLayout.columns.market, width: with300 } } };

  persistanceHelper.localstorageSet(CONSTANTS.TRADING_LAYOUT_SETTINGS_KEY, updatedLayout);
};

const tvChartIntervalUpdate = () => {
  const storedLayout = persistanceHelper.getObjFromJSON<LayoutSettings | undefined>(CONSTANTS.TV_CHART_INTERVAL_KEY, undefined);

  if (storedLayout !== undefined) {
    return;
  }

  persistanceHelper.localstorageSet(CONSTANTS.TV_CHART_INTERVAL_KEY, chartIntervals.d.value);
};

const removedKeys = ['PRO_currencies_logo', 'OT_LAYOUT_SETTINGS', 'TV_ACTIVE_INTERVAL'];
const updates = [layoutUpdate, tvChartIntervalUpdate];

export const applyLocalStorageChanges = (): void => {
  removedKeys.forEach(persistanceHelper.localStorageDelete);
  updates.forEach((update) => update());
};
