import { createRouter, createWebHistory } from 'vue-router';

import { capApp } from '@exchange/libs/utils/capacitor/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import registerError from './error-handler';
import { createRouteLocationRawFromUrl, createRouteFullPathFromUrl } from './helpers';
import getAuthGuard from './router-middleware';
import getRoutes, { checkIsCurrentBiometricRoute } from './routes';

const createAppRouter = () => {
  const router = createRouter({
    history: createWebHistory(),
    routes: getRoutes(),
    scrollBehavior: () => ({ left: 0, top: 0 }),
  });

  /** https://router.vuejs.org/guide/advanced/navigation-guards.html#navigation-guards */
  router.beforeResolve(getAuthGuard);
  router.onError(registerError);

  capApp.addListener('appUrlOpen', (data) => {
    /**  https://capacitorjs.com/docs/guides/deep-links#vue */
    const isCurrentBiometricRoute = checkIsCurrentBiometricRoute();

    const logging = (to) => logger.info(`Deep link requested ${data.url}`, 'is current biometric route', isCurrentBiometricRoute, 'going to', to);

    if (isCurrentBiometricRoute) {
      const fullPath = createRouteFullPathFromUrl(data.url);

      logging(fullPath);
      router.push({
        name: 'biometric.unlock',
        query: { redirect: fullPath },
      });
    } else {
      const slug = createRouteLocationRawFromUrl(data.url);

      logging(slug);
      router.push(slug);
    }
  });

  return router;
};

export default createAppRouter();
