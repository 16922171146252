/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import cloneDeep from 'lodash/cloneDeep';

import { logger } from '@exchange/libs/utils/simple-logger/src';

export const wssLogPrefix = '[ws]:';

export const getSafePayload = (payload: Dictionary<unknown>) => {
  const safePayload = cloneDeep(payload);

  if (typeof safePayload === 'object' && safePayload && safePayload.api_token) {
    delete safePayload.api_token;
  }

  return safePayload;
};

const initDecorators = (getDebug: () => boolean) => {
  const wssLogging = (...arg) => {
    if (getDebug()) {
      const safeArgs = arg?.map(getSafePayload);

      logger.log(wssLogPrefix, ...safeArgs);
    }
  };

  function logMethodNameAndArgs() {
    return function (_: any, propertyKey: string, descriptor: PropertyDescriptor) {
      return {
        get() {
          function wrapper(...args: Array<any>) {
            wssLogging(`function: "${propertyKey}"`, 'called with: ', ...args);

            return descriptor.value.call(this, ...args);
          }

          Object.defineProperty(this, propertyKey, {
            value: wrapper,
            configurable: true,
            writable: true,
          });

          return wrapper;
        },
      };
    };
  }

  return {
    wssLogging,
    logMethodNameAndArgs,
  };
};

export default initDecorators;
