import mitt from 'mitt';

import { logger } from '@exchange/libs/utils/simple-logger/src';

export type WebSocketManagerName = 'fastWebSocketManager' | 'bunWebSocketManager' | 'eotcWebSocketManager';
export type WebsocketEmitterType = {
  'max-connect-attempt': { name: WebSocketManagerName };
  connected: { name: WebSocketManagerName };
};

const wsEmitter = mitt<WebsocketEmitterType>();

export const onMaxConnectAttempt = (showBannerCb: (n: WebSocketManagerName) => void) => (event: { name: WebSocketManagerName }) => {
  const { name } = event;

  switch (name) {
    case 'eotcWebSocketManager': {
      showBannerCb('eotcWebSocketManager');
      return;
    }
    case 'fastWebSocketManager': {
      showBannerCb('fastWebSocketManager');

      return;
    }
    case 'bunWebSocketManager': {
      return;
    }
    default: {
      logger.log('Unknown websocket name', name);
    }
  }
};

export const onConnected = (hideBannerCb: (n: WebSocketManagerName) => void) => (event: { name: WebSocketManagerName }) => {
  const { name } = event;

  switch (name) {
    case 'eotcWebSocketManager': {
      hideBannerCb('eotcWebSocketManager');

      return;
    }
    case 'fastWebSocketManager': {
      hideBannerCb('fastWebSocketManager');

      return;
    }
    case 'bunWebSocketManager': {
      return;
    }
    default: {
      logger.log('Unknown websocket name', name);
    }
  }
};

export default wsEmitter;
