const isPromise = (p) => p && typeof p.then === 'function';
const tryCall = (value) => (typeof value === 'function' ? value() : value);

function createErrorType(name) {
  function E(message) {
    // @ts-ignore we attach it
    if (!Error.captureStackTrace) {
      this.stack = new Error().stack;
    } else {
      // @ts-ignore we attach it
      Error.captureStackTrace(this, this.constructor);
    }

    this.message = message;
  }
  E.prototype = new Error();
  E.prototype.name = name;
  E.prototype.constructor = E;
  return E;
}

export interface PromiseControllerOptions {
  timeout: number;
  timeoutReason: string;
  resetReason: string;
}

const defaults: PromiseControllerOptions = {
  timeout: 0,
  timeoutReason: 'Promise rejected by PromiseController timeout {timeout} ms.',
  resetReason: 'Promise rejected by PromiseController reset.',
};

export default class PromiseController {
  private options: PromiseControllerOptions;

  private resolveInternal: null | ((value: unknown) => void);

  private rejectInternal: null | ((reason?: unknown) => void);

  private isPendingInternal: boolean;

  private isFulfilledInternal: boolean;

  private isRejectedInternal: boolean;

  private valueInternal: unknown | undefined;

  private promiseInternal: Promise<unknown> | null;

  private timer: number | null;

  timeoutError = createErrorType('timeoutError');

  resetError = createErrorType('resetError');

  /**
   * Creates promise controller. Unlike original Promise, it does not immediately call any function.
   * Instead it has [.call()](#PromiseController+call) method that calls provided function
   * and stores `resolve / reject` methods for future access.
   */
  constructor(options: Partial<PromiseControllerOptions>) {
    this.options = { ...defaults, ...options };
    this.resolveInternal = null;
    this.rejectInternal = null;
    this.isPendingInternal = false;
    this.isFulfilledInternal = false;
    this.isRejectedInternal = false;
    this.valueInternal = undefined;
    this.promiseInternal = null;
    this.timer = null;
  }

  /** Returns promise itself. */
  get promise() {
    return this.promiseInternal;
  }

  /** Returns value with that promise was settled (fulfilled or rejected). */
  get value() {
    return this.valueInternal;
  }

  /** Returns true if promise is pending. */
  get isPending() {
    return this.isPendingInternal;
  }

  /**  Returns true if promise is fulfilled. */
  get isFulfilled() {
    return this.isFulfilledInternal;
  }

  /** Returns true if promise rejected. */
  get isRejected() {
    return this.isRejectedInternal;
  }

  /** Returns true if promise is fulfilled or rejected. */
  get isSettled() {
    return this.isFulfilledInternal || this.isRejectedInternal;
  }

  /**
   * Calls `fn` and returns promise OR just returns existing promise from previous `call()` if it is still pending.
   * To fulfill returned promise you should use
   * {@link PromiseController#resolve} / {@link PromiseController#reject} methods.
   * If `fn` itself returns promise, then external promise is attached to it and fulfills together.
   * If no `fn` passed - promiseController is initialized as well.
   *
   * @param {Function} [fn] function to be called.
   * @returns {Promise}
   */
  public call(fn: () => void | Promise<unknown>) {
    if (!this.isPendingInternal) {
      this.reset();
      this.createPromise();
      this.createTimer();
      this.callFn(fn);
    }

    return this.promiseInternal;
  }

  /** Resolves pending promise with specified `value`. */
  public resolve<T>(value: T) {
    if (this.isPendingInternal) {
      if (isPromise(value)) {
        this.tryAttachToPromise(value as Promise<unknown>);
      } else {
        this.settle(value);
        this.isFulfilledInternal = true;

        if (this.resolveInternal) {
          this.resolveInternal(value);
        }
      }
    }
  }

  /** Rejects pending promise with specified `value`. */
  public reject<T>(value: T) {
    if (this.isPendingInternal) {
      this.settle(value);
      this.isRejectedInternal = true;

      if (this.rejectInternal) {
        this.rejectInternal(value);
      }
    }
  }

  /**
   * Resets to initial state.
   * If promise is pending it will be rejected with {@link PromiseController.resetError}.
   */
  private reset() {
    if (this.isPendingInternal) {
      const message = tryCall(this.options.resetReason);
      const error = this.resetError(message);

      this.reject(error);
    }

    this.promiseInternal = null;
    this.isPendingInternal = false;
    this.isFulfilledInternal = false;
    this.isRejectedInternal = false;
    this.valueInternal = undefined;
    this.clearTimer();
  }

  /**
   * Re-assign one or more options.
   * @param options
   */
  public configure(options: PromiseControllerOptions) {
    Object.assign(this.options, options);
  }

  private createPromise() {
    this.promiseInternal = new Promise((resolve, reject) => {
      this.isPendingInternal = true;
      this.resolveInternal = resolve;
      this.rejectInternal = reject;
    });
  }

  private handleTimeout() {
    const messageTpl = tryCall(this.options.timeoutReason);
    const message = typeof messageTpl === 'string' ? messageTpl.replace('{timeout}', this.options.timeout.toString()) : '';
    const error = this.timeoutError(message);

    this.reject(error);
  }

  private createTimer() {
    if (this.options.timeout) {
      this.timer = window.setTimeout(() => this.handleTimeout(), this.options.timeout);
    }
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private settle<T>(value: T) {
    this.isPendingInternal = false;
    this.valueInternal = value;
    this.clearTimer();
  }

  private callFn(fn: () => void | Promise<unknown>) {
    if (typeof fn === 'function') {
      try {
        const result = fn();

        this.tryAttachToPromise(result);
      } catch (e) {
        this.reject(e);
      }
    }
  }

  private tryAttachToPromise(p: void | Promise<unknown>) {
    if (isPromise(p)) {
      (p as Promise<unknown>).then((value) => this.resolve(value)).catch((e) => this.reject(e));
    }
  }
}
