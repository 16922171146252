import { WSIncomingMessagePayload as SPOTIncomingMessagePayload, WSOutgoingMessagePayload as SPOTOutgoingMessagePayload } from './websocket-spot-model';
import { WebSocketManager } from './WebSocketManager';

export const FastSpotWebSocketManager = new WebSocketManager<SPOTIncomingMessagePayload, SPOTOutgoingMessagePayload>({
  url: `${process.env.VUE_APP_SPOT_SOCKET_API_URL_FAST}?x-version=3`,
  connectionTimeout: 30_000,
  withHeartbeat: true,
  name: 'fastWebSocketManager',
});

export const BunSpotWebSocketManager = new WebSocketManager<SPOTIncomingMessagePayload, SPOTOutgoingMessagePayload>({
  url: process.env.VUE_APP_SPOT_SOCKET_API_URL_BUN as string,
  connectionTimeout: 15_000,
  withHeartbeat: true,
  name: 'bunWebSocketManager',
});
