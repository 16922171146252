import dateIsInTimeRange from '@exchange/helpers/date-is-in-time-range';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';

export const shouldRedirectToMaintenance = async () => {
  const { from, to } = await launchdarkly.getWhenReady('platform-upgrade');

  const isMaintenance = dateIsInTimeRange(from, to, new Date());

  return isMaintenance;
};

export const considerRedirectToMaintenance = async (next) => {
  const shouldRedirect = await shouldRedirectToMaintenance();

  if (shouldRedirect) {
    next('maintenance');
  } else {
    next();
  }
};
