/* https://cli.vuejs.org/guide/browser-compatibility.html#usebuiltins-usage */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* smooth scrolling for Chrome, Firefox and Opera */
import smoothscroll from 'smoothscroll-polyfill';

/* window.requestIdleCallback() */
import 'requestidlecallback-polyfill';

import 'core-js/modules/es.promise.all-settled';

smoothscroll.polyfill();
