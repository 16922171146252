export const wsFrontendErrors = {
  ERROR_WEBSOCKET_CLOSED: 'ERROR_WEBSOCKET_CLOSED',
  ERROR_MAX_ATTEMPTS_REACHED: 'ERROR_MAX_ATTEMPTS_REACHED',
  MISSING_API_TOKEN: 'MISSING_API_TOKEN',
} as const;
export type WSFrontendErrors = (typeof wsFrontendErrors)[keyof typeof wsFrontendErrors];
export const wsFrontendErrorsChannel = 'SYSTEM';

export interface WSClosedErrorMessage {
  type: typeof wsFrontendErrors.ERROR_WEBSOCKET_CLOSED;
  closeEvent: CloseEvent;
  channel_name: 'SYSTEM';
}
