import { nanoid } from 'nanoid';

import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import initDecorators, { wssLogPrefix, getSafePayload } from './decorators';
import PromiseController from './promiseController';
import wsEmitter from './websocket-event-bus';
import { wsFrontendErrors, wsFrontendErrorsChannel, type WSClosedErrorMessage } from './websocket-model';
import { wsSportSAuthorizationErrors, WSIncomingEventTypes as SPOTIncomingEventTypes } from './websocket-spot-model';

const { wssLogging, logMethodNameAndArgs } = initDecorators(() => launchdarkly.flags.getLogging().wss);

const getPayloadString = (payload) => {
  const safePayload = getSafePayload(payload);

  let payloadString;

  try {
    payloadString = JSON.stringify(safePayload);
  } catch (e) {
    // ignore
  }

  return payloadString || payload;
};

export interface WebSocketListener<IncomingMessage> {
  onEvent: (event: IncomingMessage) => void;
  onConnection: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class WebSocketManager<IncomingMessage extends Record<string, any>, OutgoingMessage extends Record<string, any>> {
  private websocket?: WebSocket;

  private listeners: Array<WebSocketListener<IncomingMessage>> = [];

  private connectionInterval?: number;

  private inFlightRequests = new Array<{
    id: string;
    successMatcher: (message: IncomingMessage) => boolean;
    failureMatcher?: (message: IncomingMessage) => boolean;
    resolve: (payload: IncomingMessage) => void;
    reject: (payload: WSClosedErrorMessage | IncomingMessage) => void;
    timeoutId: number;
  }>();

  get isOpening() {
    return this.getReadyState() === WebSocket.CONNECTING;
  }

  get isOpened() {
    return this.getReadyState() === WebSocket.OPEN;
  }

  get isClosing() {
    return this.getReadyState() === WebSocket.CLOSING;
  }

  get isClosed() {
    return this.getReadyState() === WebSocket.CLOSED;
  }

  private opening!: PromiseController;

  private closing!: PromiseController;

  private gotMessage = false;

  private openingAttempts = 0;

  private openingAttemptsMax = 5;

  private getMaxOpeningAttemptsReached = (funcName: string) => {
    if (this.openingAttempts >= this.openingAttemptsMax) {
      logger.log(`${wssLogPrefix}${this.config.name} :${funcName}: connection attempt number (${this.openingAttempts}) >= ${this.openingAttemptsMax}`);

      return true;
    }

    return false;
  };

  constructor(private config: { url: string; connectionTimeout: number; withHeartbeat: boolean; name: 'fastWebSocketManager' | 'bunWebSocketManager' | 'eotcWebSocketManager' }) {
    this.resetConnectionState();
  }

  private resetConnectionState() {
    this.opening = new PromiseController({
      timeout: this.config.connectionTimeout,
      timeoutReason: `Can't open WebSocket within allowed timeout: ${this.config.connectionTimeout} ms.`,
    });

    this.closing = new PromiseController({
      timeout: this.config.connectionTimeout,
      timeoutReason: `Can't close WebSocket within allowed timeout: ${this.config.connectionTimeout} ms.`,
    });
  }

  public getReadyState = () => this.websocket?.readyState; // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState

  private onMessage = (event: MessageEvent<string>) => {
    const payload: IncomingMessage = JSON.parse(event.data);

    this.processMessage(payload);

    if (event.type === SPOTIncomingEventTypes.ERROR) {
      this.log('WSIncomingEventTypes.ERROR', event);
    }

    this.listeners.forEach((l) => l.onEvent(payload));
  };

  private onClose = (event: CloseEvent) => {
    this.log('onClose', event);

    if ((Object.values(wsSportSAuthorizationErrors) as Array<string>).includes(event.reason)) {
      // Account session was closed (maybe logout in a different browser window?)
      logger.log(`${wssLogPrefix}${this.config.name} WSS closed with ${event.reason} - reloading Page`);
      window.location.reload();
    }

    this.closing.resolve(event);
    const error = new Error(`WebSocket closed with reason: ${event.reason} (${event.code}).`);

    if (this.opening.isPending) {
      this.opening.reject(error);
    }

    this.resetConnectionState();

    this.cleanup(error, 'on-close');

    // reset all InFlightRequests when the connection gets closed
    // reject will also remove the request from the array - so we need a while loop.
    while (this.inFlightRequests.length > 0) {
      const [fir] = this.inFlightRequests;

      if (fir) {
        logger.log(`${wssLogPrefix}${this.config.name} rejecting request ${fir.id} due to wss close`);
        fir.reject({
          channel_name: wsFrontendErrorsChannel,
          type: wsFrontendErrors.ERROR_WEBSOCKET_CLOSED,
          closeEvent: event,
        });
      }
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
    if (event.code > 1000) {
      if (this.getMaxOpeningAttemptsReached('onClose')) {
        return;
      }

      retryService.waitForNextRetryTick().then(() => {
        logger.log(`${wssLogPrefix}${this.config.name} reopening connection`, event.code);
        return this.connect('wss reopening').catch((e) => {
          logger.log(`${wssLogPrefix}${this.config.name} reconnect failed`, e);
        });
      });
    }
  };

  private onOpen = (event: Event) => {
    this.log('onOpen', event);

    this.openingAttempts = 0;
    wsEmitter.emit('connected', { name: this.config.name });
    this.opening.resolve(event);

    this.listeners.forEach((l) => l.onConnection());
  };

  private onError = (error: Event) => {
    // error will also call close
    this.log('onError', error);
  };

  @logMethodNameAndArgs()
  private createWS() {
    this.openingAttempts += 1;
    this.websocket = new WebSocket(`${this.config.url}`);

    this.websocket.addEventListener('message', this.onMessage, { passive: true });
    this.websocket.addEventListener('close', this.onClose, { passive: true, once: false });
    this.websocket.addEventListener('error', this.onError, { passive: true, once: true });
    this.websocket.addEventListener('open', this.onOpen, { passive: true, once: true });

    if (this.config.withHeartbeat) {
      this.runConnectionTimeout();
    }
  }

  private cleanup(error?: Error, info?: string) {
    this.log('cleanup', error, info);

    if (this.connectionInterval) {
      clearInterval(this.connectionInterval);
    }

    this.websocket?.removeEventListener('message', this.onMessage);
    this.websocket?.removeEventListener('close', this.onClose);
    this.websocket?.removeEventListener('error', this.onError);
    this.websocket?.removeEventListener('open', this.onOpen);

    this.websocket = undefined;
  }

  @logMethodNameAndArgs()
  public async connect(calledFrom: string) {
    const logPrefix = 'Connect:: ';

    this.log(`${logPrefix}called from`, `"${calledFrom}"`, this.getConnectionStateString());

    if (this.getMaxOpeningAttemptsReached('connect')) {
      wsEmitter.emit('max-connect-attempt', { name: this.config.name });
      return Promise.reject(new Error(wsFrontendErrors.ERROR_MAX_ATTEMPTS_REACHED));
    }

    if (this.isClosing) {
      this.log(`${logPrefix}connection is closing, waiting...`);

      if (!this.closing.promise) {
        return Promise.reject(new Error(`${logPrefix}connection is closing but there is no promise`));
      }

      await this.closing.promise;
    }

    if (this.isOpened) {
      if (!this.opening.promise) {
        return Promise.reject(new Error(`${logPrefix}connection is opened but there is no promise`));
      }

      return this.opening.promise;
    }

    this.log(`${logPrefix}called from`, `"${calledFrom}"`, ` attempt number ${this.openingAttempts}`);

    return this.opening
      .call(() => {
        this.log(`${logPrefix}gonna create a connection`);
        this.opening.promise?.catch((e) => {
          this.cleanup(e, `${logPrefix}opening.promise catch`);
          this.resetConnectionState();
        });
        this.createWS();
      })
      ?.catch((e) => {
        this.log(`${logPrefix}opening.call failed; attempt number ${this.openingAttempts}`, e);

        if (this.getMaxOpeningAttemptsReached('connect')) {
          wsEmitter.emit('max-connect-attempt', { name: this.config.name });
        }
      });
  }

  @logMethodNameAndArgs()
  public async disconnect(calledFrom: string, code = 1000) {
    const logPrefix = 'Disconnect:: ';

    this.log(`${logPrefix}called from`, `"${calledFrom}"`, 'code:', code, this.getConnectionStateString());

    this.openingAttempts = 0;

    if (this.isClosing) {
      if (!this.closing.promise) {
        this.log(`${logPrefix}isClosing but there is no promise`);
      } else {
        await this.closing.promise;
      }
    }

    if (this.isClosed) {
      return Promise.resolve(this.closing.value);
    }

    return this.closing
      .call(() => {
        // sometimes the Websockets take like 15 seconds fire onClose after we call websocket.close()
        // so we speed it up by calling onClose ourselves after unsubscribing from all events.
        this.websocket?.close(code, 'disconnect was called');
        this.cleanup(undefined, 'disconnect');
        this.onClose({ code } as CloseEvent);
      })
      ?.catch((e) => {
        this.log(`${logPrefix}closing.call failed`, e);
        return this.disconnect(calledFrom, code);
      });
  }

  @logMethodNameAndArgs()
  public async subscribe(listener: WebSocketListener<IncomingMessage>) {
    const calledFrom = 'wss subscribe';

    await this.connect(calledFrom);

    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);

      if (this.listeners.length === 0) {
        this.disconnect(calledFrom);
      }
    };
  }

  @logMethodNameAndArgs()
  private async send(message: OutgoingMessage) {
    const sendFn = () => this.websocket?.send(JSON.stringify(message));

    await this.connect('wss send');

    return sendFn();
  }

  public async request(
    {
      message,
      successMatcher,
      failureMatcher,
    }: {
      message: OutgoingMessage;
      successMatcher: (message: IncomingMessage) => boolean;
      failureMatcher?: (message: IncomingMessage) => boolean;
    },
    timeout = 10_000,
  ) {
    const id = nanoid();

    this.log('request', id, getPayloadString(message));

    return new Promise<IncomingMessage>((resolve, reject) => {
      let timeoutFunction = () => reject(new Error(`Timeout reached for message ${id}: ${JSON.stringify(message).replaceAll(/"api_token":".*"/gm, '"api_token":"XXX"')}`));

      const timeoutId = setTimeout(() => timeoutFunction(), timeout);
      const removeInFlightRequest = (warningMessageType: 'resolved' | 'rejected') => {
        const index = this.inFlightRequests.findIndex((r) => r.id === id);

        clearTimeout(timeoutId);

        timeoutFunction = () => {
          logger.warn(`${wssLogPrefix} logger WHAT ARE WE DOING HERE?`);
        };

        if (index > -1) {
          this.inFlightRequests.splice(index, 1);
        } else {
          logger.log(`${wssLogPrefix}${this.config.name} warning: ${warningMessageType} unknown request`);
        }
      };

      this.inFlightRequests.push({
        id,
        successMatcher,
        failureMatcher,
        resolve: (payload) => {
          this.log(`Request ${id} resolved with ${getPayloadString(payload)}`);
          removeInFlightRequest('resolved');
          resolve(payload);
        },
        reject: (payload) => {
          this.log(`Request ${id} rejected with ${getPayloadString(payload)}`);
          removeInFlightRequest('rejected');
          reject(payload);
        },
        timeoutId: timeout,
      });

      this.send(message).catch((e) => {
        this.log(`Request ${id} rejected with ${getPayloadString(e)}`);
        removeInFlightRequest('rejected');
        reject(e);
      });
    });
  }

  private extendConnectionTimeout() {
    this.gotMessage = true;
  }

  private runConnectionTimeout() {
    if (this.connectionInterval) {
      clearInterval(this.connectionInterval);
    }

    this.connectionInterval = window.setInterval(() => {
      if (!this.gotMessage) {
        logger.log(`${wssLogPrefix}${this.config.name} heartbeat timeout hit => disconnect`);
        this.disconnect('heartbeat', 3001);
      }

      this.gotMessage = false;
    }, this.config.connectionTimeout);
  }

  private processMessage(payload: IncomingMessage) {
    this.extendConnectionTimeout();

    this.inFlightRequests.forEach((r) => {
      if (r.successMatcher(payload)) {
        this.log('requestSuccess', r.id);
        r.resolve(payload);
      }

      if (r.failureMatcher?.(payload)) {
        this.log('requestFail', r.id);
        r.reject(payload);
      }
    });

    if (payload.type === 'CONNECTION_CLOSING') {
      this.disconnect('CONNECTION_CLOSING', 3001);
    }
  }

  private log(...args) {
    wssLogging(`[${this.config.name}]:`, ...args);
  }

  private getConnectionStateString() {
    const state = {
      isOpening: this.isOpening,
      isOpened: this.isOpened,
      isClosing: this.isClosing,
      isClosed: this.isClosed,
    };

    return JSON.stringify(state);
  }
}

export default WebSocketManager;
